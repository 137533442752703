<template>
    <div class="menus">
        <el-menu :default-active="activeMenu" :collapse="isCollapse" background-color="#262d3c" text-color="#fff" :unique-opened="true" :collapse-transition="true" mode="vertical">
            <template v-for="item in routes">
                <el-submenu v-if="item.children && item.children.length > 1" ref="subMenu" :index="item.meta && item.meta.component ? item.meta.component : item.path" :key="item.meta.menuId" popper-append-to-body>
                    <template slot="title" v-if="item.meta">
                        <i :class="item.meta.icon"></i>
                        <span>{{ item.meta.title }}</span>
                    </template>
                    <template v-for="child in item.children">
                        <el-menu-item :key="child.meta.menuId" v-if="!Number(child.meta.visible)" :index="resolvePath(item.path, child.path)" @click.native="linkProps(child, item)">{{ child.meta.title }}</el-menu-item>
                    </template>
                </el-submenu>
                <el-menu-item v-else-if="item.meta" :key="item.meta.menuId" :index="resolvePath(item.path, item.path)" @click.native="linkProps(item, item.path)">
                    <i :class="item.meta && item.meta.icon ? item.meta.icon : ''" style="color: #fff"></i>
                    <span slot="title">{{ item.meta.title }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import path from 'path';
export default {
    name: 'MyMenus',
    props: {
        defaultActive: {
            type: String,
            default: '1-1'
        },
        isCollapse: {
            type: Boolean,
            default: true
        },
        checkedMenu: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    computed: {
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        }
    },
    data() {
        return {
            role: 1,
            fullscreenLoading: false, //全屏Loading
            passwordDialogVisible: false, //修改密码
            //  isCollapse:false,        //菜单是否收起
            infoVisible: false, //个人中心模态框是否显示
            isFullScreen: false, //是否全屏
            active: '',
            basePath: '',
            tmpPath: null,
            routes: []
            // defaultActive:'1-1',          //默认展开第几个菜单
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.username = this.userInfo.username || '匿名用户';
        this.nickname = this.userInfo.nickname;
        this.routes = [];
        this.tmpPath = null;
        this.renderingMenu();
    },
    methods: {
        renderingMenu() {
            this.showMenusFlag = false;
            let routesTime = setInterval(() => {
                if (localStorage.getItem('resetRoutes')) {
                    clearInterval(routesTime);
                    this.routes = JSON.parse(localStorage.getItem('routes'));
                    this.$forceUpdate();
                    this.showMenusFlag = true;
                    localStorage.removeItem('resetRoutes');
                }
            }, 500);
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus(isFold) {
            this.isFold = isFold;
            this.$emit('handleFoldMenus', isFold);
        },
        linkIndexKey(child, parent) {
            let _path = parent + '/' + child.path;
            if (child.children) {
                _path = parent + '/' + child.children[child.children.length - 1].path;
            } else {
                _path = parent.path + '/' + child.path;
            }
            return _path;
        },
        linkProps(child, parent) {
            if (child.meta && child.meta.menuId && child.meta.title) {
                this.dataCollection({ eventId: 600, caseNo: child.meta.menuId, extStr: child.meta.title, remark: '菜单埋点' });
            }

            let _path = parent + '/' + child.path;
            if (child.children) {
                _path = parent + '/' + child.children[child.children.length - 1].path;
            } else {
                _path = parent.path + '/' + child.path;
            }
            if (_path != this.tmpPath) {
                this.$router.push({
                    path: _path,
                    query: {
                        t: +new Date() //保证每次点击路由的query项都是不一样的，确保会重新刷新view
                    }
                });
                this.tmpPath = _path;
            }
        },
        resolvePath(basePath, routePath) {
            return path.resolve(basePath, routePath);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu_leval1 {
    display: flex;
    align-items: center;
}
.menu_leval1 div {
    width: 20px;
    margin-right: 10px;
}
.menus {
    position: absolute;
    top: 0.8rem;
    left: 0;
    bottom: 0;
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    z-index: 10;
    padding: 0;
    user-select: none;
    padding-top: 10px;
    background: #262d3c;
}
.el-menu {
    border: 0;
    width: 200px;
    background-color: #262d3c;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.el-menu--collapse.el-menu {
    width: 64px;
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
.menus .el-icon-model_1 {
    width: 20px !important;
    height: 20px;
    /* background: url(../../public/images/ICon2.png) no-repeat; */
    background-size: 100% 100%;
}
/deep/ .el-menu-item:hover,
/deep/ .el-submenu__title:hover {
    background-color: rgb(255, 255, 255, 0.2) !important;
}
/deep/.el-submenu__title i {
    color: #fff;
}
/deep/.el-submenu .el-menu-item {
    height: 40px;
    line-height: 40px;
}
</style>