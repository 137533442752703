<template>
    <div class="wrap">
        <div class="login_box">
            <p class="login_title">登录</p>
            <el-form class="login_form" @keyup.enter.native="handleLogin" ref="form" :model="form">
                <el-form-item>
                    <el-input v-model="form.username" size="medium" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.password" size="medium" placeholder="请输入密码" type="password"></el-input>
                </el-form-item>
                <el-form-item class="qrcode" v-if="isVerification">
                    <el-input class="qrcode_input" v-model="form.code" placeholder="请输入验证码"></el-input>
                    <div v-if="qrcodeSrc" class="verify-box" @click="refreshCode">
                        <img :src="'data:image/jpeg;base64,' + qrcodeSrc.img" alt="" />
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="submit_btn" size="medium" :disabled="isDisabled" @click="handleLogin">登 录</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="version">Copyright @{{ new Date().getFullYear() }}</div>
    </div>
</template>
<script>
import { setTimeToken, intervalRefreshToken } from '../utils/auth.js';
import { menuLoad, handleMenuData } from '../router/index.js';
export default {
    name: 'Login',
    components: {},
    data() {
        return {
            codeTime: 0, //验证码时间
            redirect: '',
            isVerification: true,
            isDisabled: false,
            identifyCode: '',
            identifyCodes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
            passwordShow: false, //密码是否显示
            codeActive: false, //发送验证码按钮颜色
            isClickCode: false, //是否点击过发送验证码
            form: {
                code: '',
                username: '', //用户名
                password: '' //密码
            },
            qrcodeSrc: ''
        };
    },
    watch: {
        $route: {
            handler(route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    },
    mounted() {
        this.getCaptcha();
    },
    methods: {
        // 获取图形验证码
        getCaptcha() {
            this.instance
                .get('/upms/api/captcha/generate', {})
                .then((res) => {
                    var data = res.data;
                    if (data.code === 200) {
                        this.qrcodeSrc = data.data;
                    }
                })
                .catch((res) => {});
        },
        refreshCode() {
            this.qrcodeSrc = '';
            this.getCaptcha();
        },
        //发送验证码
        sendCode() {
            var _this = this;
            if (!this.form.username) {
                _this.$message.error('抱歉，请输入用户名');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (this.codeTime <= 0) {
                _this.codeTime = 60;
                this.isClickCode = true;
                this.instance.post('/upms/api/user/loginSms', this.form).then((res) => {
                    if (res.data.code == 200) {
                        var timer = setInterval(function () {
                            _this.codeTime--;
                            if (_this.codeTime <= 0) {
                                _this.isClickCode = false;
                                _this.codeActive = true;
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        _this.codeTime = 0;
                        _this.codeActive = false;
                        _this.isClickCode = false;
                        _this.$message.error(res.data.message);
                    }
                });
            }
        },
        //点击提交按钮的方法
        handleLogin() {
            var _this = this;
            if (!_this.form.username) {
                _this.$message.error('抱歉,请输入用户名!');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (!_this.form.code) {
                _this.$message.error('抱歉,请输入验证码!');
                return false;
            }
            this.isDisabled = true;
            this.form.uuid = this.qrcodeSrc.uuid;
            this.instance
                .post('/upms/api/user/login', this.form)
                .then((res) => {
                    var data = res.data;
                    this.isDisabled = false;
                    if (data.code == 200) {
                        this.userMenuTree();
                        localStorage.setItem('AUTH_TOKEN', data.data.accessToken);
                        setTimeToken(data.data.accessToken);
                        localStorage.setItem('username', data.data.userName);
                        localStorage.setItem('userInfo', JSON.stringify(data.data));
                        this.dataCollection({ eventId: 101, remark: '用户登录' });

                        menuLoad(true);

                        if (this.$route.query && this.$route.query.redirect) {
                            _path = this.$route.query.redirect;
                            setTimeout(() => {
                                location.reload();
                            }, 10);
                        }
                        _this.$router.push({ path: _path });
                    } else {
                        this.getCaptcha();
                        this.form.code = null;
                        this.$message.error(data.message);
                    }
                })
                .catch((res) => {
                    this.isDisabled = false;
                });
        },
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', { params: this.form })
                .then((res) => {
                    let data = res.data;
                    let _path = '/bigScreen/index';
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(res.data.data));
                        handleMenuData(data.data);
                        let menus = data.data;
                        if (!menus[0].children) {
                            _path = menus[0].path + '/index';
                        } else {
                            _path = menus[0].path + menus[0].children[0].path;
                        }
                        localStorage.setItem('jumpPath', _path);
                        this.$router.push({ path: _path });
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    }
};
</script>
<style scoped>
.verify-box {
    margin-left: 0.2rem;
    width: 86px;
}

.qrcode_input {
    width: 190px;
}
.login_box .submit_btn {
    width: 100%;
    background: #22bdbd;
    border-color: #22bdbd;
    color: #fff;
}
.login_box .el-button:focus,
.login_box .el-button:hover,
.code_btn:hover {
    background: #81eff0;
    border-color: #81eff0;
    color: #4c6161;
}
.verify-box {
    margin-left: 0.2rem;
}
.code_btn {
    width: 125px;
    margin-left: 15px;
    background: #22bdbd;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.code_btn.active {
    color: #aaa;
    background: #eeeeee;
}
.qrcode /deep/.el-form-item__content {
    display: flex;
    width: 100%;
}
.wrap {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url(../../public/images/login_bg.jpg);
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: cover;
    background-position: center;
}
.version {
    position: absolute;
    bottom: 0.5rem;
    right: 20%;
    z-index: 50;
    color: #858688;
}
.login_box {
    /* width:4.2rem;
	    height:5.9rem; */
    width: 350px;
    height: 400px;
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translate(-12%, -50%);
    z-index: 50;
    background: url(../../public/images/login_form.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 0.1rem;
    padding: 45px 30px;
    /* color: #fff; */
}
.login_box .el-form-item__label {
    color: #fff !important;
}
.login_box .el-input__inner {
    background-color: rgba(255, 255, 255, 0.1);
    /* color: #fff; */
}
.login_title {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
}
.login_form {
    width: 100%;
    margin: 0 auto 0;
}
.login_form .el-input__inner {
    color: #cfdde5;
}
.login_btn button {
    border: none;
    background: #003b8f;
}
.login_btn button:hover {
    background: #01265a;
}
.user,
.password {
    width: 100%;
    /* height:0.48rem; */
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-bottom: 1px solid #dfe5ec;
    padding: 0 0.16rem;
}
.password {
    justify-content: space-between;
}
.user span,
.password span {
    width: 0.14rem;
}
.password_box {
    display: flex;
    align-items: center;
}

.isshowpass {
    cursor: pointer;
    width: 0.22rem;
}
.login_btn {
    margin-top: 30px;
}
.login_btn button {
    width: 100%;
}
</style>