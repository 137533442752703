import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import store from "../store";
import Login from "../views/login/index.vue";
// import NetworkAnalyse from "../views/networkAnalyse/index.vue";
import {
    getToken,
    intervalRefreshToken,
    setTimeToken,
    getTimeToken,
} from "../utils/auth.js";

Vue.use(VueRouter);
let routes = [
    {
        path: "/vue/*",
        component: Layout,
    },
    {
        path: "/",
        name: "Login",
        showHidden: true,
        meta: {
            title: "宏瓴科技",
            showHidden: true,
            menuId: 1,
        },
        component: Login,
    },
    // {
    //     path: "/networkAnalyse/index",
    //     name: "networkAnalyse",
    //     showHidden: true,
    //     meta: {
    //         title: "宏瓴科技",
    //         showHidden: true,
    //         menuId: 1,
    //     },
    //     component: NetworkAnalyse,
    // },
];

// 动态重置路由
function resetRoutes(routesData) {
    routes = [
        {
            path: "/vue/*",
            component: Layout,
        },
        {
            path: "/",
            name: "Login",
            showHidden: true,
            // meta: {
            //     title: "宏瓴科技",
            //     showHidden: true,
            //     menuId: 1,
            // },
            component: () => Login,
        },
    ];

    let userMenu = JSON.parse(localStorage.getItem("userMenu")) || []; //路由数据
    for (let i = 0; i < userMenu.length; i++) {
        let tmpUserMenu = userMenu[i];
        let tmpRoute = {
            path: tmpUserMenu.path,
            component: Layout,
            name: tmpUserMenu.path.slice(1, tmpUserMenu.path.length),
            meta: {
                title: tmpUserMenu.menuName,
                icon: tmpUserMenu.icon,
                menuId: tmpUserMenu.menuId,
            },
            children: [],
        };

        let childrenItem = {};
        if (tmpUserMenu.children && tmpUserMenu.children.length > 0) {
            for (let t = 0; t < tmpUserMenu.children.length; t++) {
                const tmpItem = tmpUserMenu.children[t];
                tmpItem.path = tmpItem.path.slice(1, tmpItem.path.length);
                childrenItem = {
                    path: tmpItem.path,
                    name: tmpItem.path,
                    meta: {
                        title: tmpItem.menuName,
                        menuId: tmpItem.menuId,
                        perms:
                            tmpItem.perms != tmpUserMenu.menuName
                                ? tmpItem.perms
                                : null,
                        component: tmpItem.component,
                        visible: tmpItem.visible,
                    },
                };

                if (tmpUserMenu.path != "/vue") {
                    childrenItem.component = (resolve) =>
                        require([
                            "@/views" +
                                tmpItem.component +
                                "/" +
                                tmpItem.path +
                                ".vue",
                        ], resolve);
                }

                tmpRoute.children.push(childrenItem);
            }
        } else {
            childrenItem = {
                path: "index",
                name: tmpUserMenu.menuName,
                meta: {
                    title: tmpUserMenu.menuName,
                    menuId: tmpUserMenu.menuId,
                    component: tmpUserMenu.component,
                },
            };
            childrenItem.component = (resolve) =>
                require(["@/views" + tmpUserMenu.path + "/index"], resolve);
            tmpRoute.children.push(childrenItem);
        }
        routes.push(tmpRoute);
    }

    // console.log("整理路由：");
    // console.log(routes);
    localStorage.setItem("routes", JSON.stringify(routes));
    localStorage.setItem("resetRoutes", "1");
    routes.forEach((v) => {
        router.addRoute(v);
    });
}

// 校验路由地址合法性  不合法，跳转首个菜单。
function checkRouterPath(urlPath) {
    let patStrArr = urlPath.split("/");
    let menuData = JSON.parse(localStorage.getItem("userMenu")) || []; //路由数据
    let isLegal = true;
    if (menuData && menuData.length > 0) {
        for (var i = 0; i < menuData.length; i++) {
            if (menuData[i].path === "/" + patStrArr[1]) {
                isLegal = true;
                break;
            }
        }
    }
    return isLegal;
}

function hrefPath(legalFlag) {
    let menuData = JSON.parse(localStorage.getItem("userMenu")) || []; //路由数据
    let _path = "";
    if (!legalFlag && menuData[0]) {
        if (menuData[0] && !menuData[0].children) {
            _path = menuData[0].path + "/index";
        } else {
            _path = menuData[0].path + menuData[0].children[0].path;
        }
    }
    return _path;
}

let router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
});

// 路由守卫
let hrefFlag = false;
router.beforeEach((to, from, next) => {
    setTimeout(() => {
        let elements = document.getElementById("app").querySelectorAll("*");
        let hrefToPath = hrefPath(0);
        if (elements.length == 0 && hrefToPath) {
            next({
                replace: true,
                path: hrefToPath,
            });
        }
    }, 1000);

    if (!checkRouterPath(to.path) && !hrefFlag) {
        hrefFlag = true;
        next({
            replace: true,
            path: hrefPath(checkRouterPath(to.path)),
        });
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    // 检测path首字母是否大写字母
    //   to.path != '/Home' && pathNameCapitalization(to.path)

    if (
        to.fullPath.indexOf("redirect") != -1 &&
        localStorage.getItem("tokenError") == 401
    ) {
        next({
            replace: true,
            name: "首页",
            path: "/",
        });
    }

    if (to.path == "/vue/") {
        next({
            replace: true,
            name: "首页",
            path: "/",
        });
    }

    if (getToken()) {
        let jumpPath = localStorage.getItem("jumpPath");

        if (!jumpPath) {
            localStorage.clear();
            next();
            return;
        }

        if (to.path == "/") {
            next({
                replace: true,
                path: localStorage.getItem("jumpPath"),
            });
            return;
        }

        next();
    } else {
        switch (to.path) {
            case "/":
                next();
                break;
            case "/networkAnalyse/index":
                next();
                break;

            default:
                next({
                    replace: true,
                    name: "Login",
                });
                break;
        }
    }
});

/** 解决跳转重复路由报错问题   */

const originPush = router.push;
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        );
    }
};
export default router;

export function handleMenuData(data) {
    localStorage.removeItem("headerMenus");
    localStorage.removeItem("routes");
    data && resetRoutes(data);
}

export function menuLoad(data) {
    data && rsetRoute();
}
